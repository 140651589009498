import { ButtonBase, Checkbox, List, ListItem, styled, Typography } from "@mui/material";
import ImagePlaceholder from "@/assets/img/placeholder_image_small.svg";
import AudioPlaceholder from "@/assets/img/placeholder_audio_small.svg";
import VideoPlaceholder from "@/assets/img/placeholder_video_small.svg";
import ThreeDPlaceholder from "@/assets/img/placeholder_3d_small.svg";
import Styles from "@/assets/styles/Styles";
import DataListChip from "@/components/DataList/common/DataListChip";
import DataListOptions from "@/components/DataList/common/DataListOptions";
import DataListTitle from "@/components/DataList/common/DataListTitle";
import DataListUpdatedByAt from "@/components/DataList/common/DataListUpdatedByAt";
import type { CommonDataListProps } from "@/components/DataList/DataList";
import type { DataListRowInternal } from "@/components/DataList/DataListRow";
import Image from "@/components/Image";
import type BaseModel from "@/declarations/models/BaseModel";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import Api from "@/services/Api";

export interface DataListViewProps<T> extends CommonDataListProps {
  items: Array<DataListRowInternal<T>>;
  getIsSelected: (item: DataListRowInternal<T>) => boolean;
  onSelectChanged?: (item: DataListRowInternal<T>, selected: boolean) => void;
  enableSelection?: boolean;
  selectedItems?: string[];

  onItemClick?: (item: DataListRowInternal<T>) => void;
  onEdit?: (item: DataListRowInternal<T>) => void;
  onDelete?: (item: DataListRowInternal<T>) => void;
  onDeleteForever?: (item: DataListRowInternal<T>) => void;
  onUndelete?: (item: DataListRowInternal<T>) => void;
}

const SelectionButtonWrapper = styled("span")(() => ({
  paddingRight: "16px",
  flex: "0 1 auto",
  "& .MuiCheckbox-root": {
    padding: "6px",
  },
}));

const ContentContainer = styled(ButtonBase)`
  flex: 1 auto;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  gap: 16px;
  &.deletedOrSelected {
    opacity: 0.5;
  }
`;

const ListWrapper = styled(List)({
  padding: 0,
});

const ListViewItem = styled(ListItem)`
  min-height: 80px;
  padding: 0 16px;
  &.enabled {
    :hover {
      background-color: ${Styles.Colors.THEME_BACKGROUND_PRIMARY};
    }
  }
`;

const ImageContainer = styled("div")`
  width: 64px;
  height: 64px;
  overflow: hidden;
  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

const MainContainer = styled("span")`
  flex: 1 0 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.column {
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-top: 4px;
    }
  }
`;

const TitleContainer = styled("span")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InfoContainer = styled("span")`
  flex: 1 0 18%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

async function resolveMediaUrlFromID(id: number): Promise<string | undefined> {
  const media = await Api.getOneMedia(id).fetchDirect(null);
  if (media) {
    return media.thumbnail_src;
  }
}
const mediaTypePlaceholderMap: { [mt in MediaType]: string | undefined } = {
  [MediaType.IMAGE]: ImagePlaceholder,
  [MediaType.AUDIO]: AudioPlaceholder,
  [MediaType.VIDEO]: VideoPlaceholder,
  [MediaType.DOCUMENT]: ImagePlaceholder, // FIXME: Add proper fallback-image for Document
  [MediaType.YOUTUBE]: VideoPlaceholder,
  [MediaType.VIMEO]: VideoPlaceholder,
  [MediaType.SKETCHFAB]: ThreeDPlaceholder,
  [MediaType.DM]: ImagePlaceholder, // FIXME: Add proper fallback-image for DM
  [MediaType.MODEL_3D]: ThreeDPlaceholder,
};

export const ListView = <T extends object>({
  items,
  getIsSelected,
  onSelectChanged,
  onItemClick,
  onEdit,
  onDelete,
  onDeleteForever,
  onUndelete,
  onTagClicked,
  selectedItems,
  enableSelection,
  altUpdatedAt,
  hideUpdated = false,
  denseOptions = false,
  hideImage,
  fallbackImage,
  customListItemButtons,
}: DataListViewProps<T>) => {
  const handleSelectionChange = (item: DataListRowInternal<T>, selected: boolean) =>
    enableSelection && onSelectChanged!(item, selected);

  return (
    <ListWrapper>
      {items.map((item) => {
        let imageSrc: string | Promise<string | undefined> | undefined;
        if (!isNaN(Number(item.imageURL)) && Number(item.imageURL) > 0) {
          // If the image source is just a number, assume it is referencing a media object
          imageSrc = resolveMediaUrlFromID(Number(item.imageURL));
        } else if (typeof item.imageURL === "string") {
          imageSrc = item.imageURL;
        }
        const sourceItem = item.sourceItem as BaseModel;
        const selected = getIsSelected(item);
        const isDeleted = !!sourceItem.deleted_at;
        const wasSelected = selectedItems?.includes(item.key);
        const enabled = !!onItemClick && !isDeleted && !wasSelected;
        return (
          <ListViewItem key={item.key} divider className={enabled ? "enabled" : undefined}>
            {enableSelection && (
              <SelectionButtonWrapper>
                <Checkbox
                  color="primary"
                  checked={selected ?? wasSelected}
                  onChange={() => handleSelectionChange(item, !selected)}
                />
              </SelectionButtonWrapper>
            )}

            <ContentContainer
              onClick={() => onItemClick?.(item)}
              disabled={!enabled}
              className={isDeleted || wasSelected ? "deletedOrSelected" : undefined}
            >
              {!hideImage && (
                <ImageContainer>
                  <Image
                    src={imageSrc}
                    alt={item.title}
                    fallbackImage={
                      Object.prototype.hasOwnProperty.call(sourceItem, "media_type")
                        ? mediaTypePlaceholderMap[(sourceItem as Media).media_type]
                        : fallbackImage
                    }
                    isWebPFormat
                  />
                </ImageContainer>
              )}
              <MainContainer className={Array.isArray(item.chipContent) ? "column" : undefined}>
                <TitleContainer>
                  <DataListTitle item={item} />
                  {!altUpdatedAt ? <DataListTitle item={item} subtitle /> : <DataListUpdatedByAt item={item} />}
                </TitleContainer>
                {!!item.chipContent && <DataListChip chipContent={item.chipContent} onTagClicked={onTagClicked} />}
              </MainContainer>
              <InfoContainer>
                {!!item.infoText && (
                  <Typography variant={"caption"} fontSize={"1em"} fontWeight={700}>
                    {item.infoText}
                  </Typography>
                )}
                {!hideUpdated && <DataListUpdatedByAt item={item} />}
              </InfoContainer>
            </ContentContainer>

            <DataListOptions
              item={item}
              denseOptions={denseOptions}
              isDeleted={isDeleted}
              handleEditClick={onEdit}
              handleDeleteClick={onDelete}
              handleDeleteForeverClick={onDeleteForever}
              handleUndelete={onUndelete}
              customButtons={customListItemButtons}
            />
          </ListViewItem>
        );
      })}
    </ListWrapper>
  );
};

export default ListView;
