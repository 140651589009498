import { Search } from "@mui/icons-material";
import { Box, InputAdornment, styled, Typography } from "@mui/material";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import SearchField from "@/components/SearchField";
import type Document from "@/declarations/models/Document";
import type PaginationResult from "@/declarations/PaginationResult";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import { useStore } from "@/Store";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

export interface DocumentListModalContentProps {
  schemaIds: number[];
  onSelect: (document: Document) => void;
  mapper: (document: Document) => DataListRow;
  selectedItems?: string[];
  selectedLanguage?: string;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  height: "100%",
  width: "100%",
});

const DocumentListModalContent: FC<DocumentListModalContentProps> = ({
  schemaIds,
  onSelect,
  mapper,
  selectedItems,
  selectedLanguage,
}) => {
  const { state } = useStore();
  const instance = state.cmsContextInstance;
  const { t, i18n } = useTranslation("common");
  const resolveLocalizedString = resolvePotentiallyLocalizedString(i18n.language);

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    defaultSortBy: "name",
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [sharedInstances, setSharedInstances] = useState<ApplicationInstance[]>([]);
  const [selectedInstanceId, setSelectedInstanceId] = useState(instance?.id);

  const resetPageDeps = useMemo(() => [searchInput, selectedInstanceId], [searchInput, selectedInstanceId]);
  const externalDataChanged = useMemo(
    () => ({ lastFetchedTimestamp, selectedInstanceId }),
    [lastFetchedTimestamp, selectedInstanceId]
  );

  useEffect(() => {
    if (instance?.id) {
      Api.getSharedInstances(instance.id, { type: "document" })
        .fetchDirect(null)
        .then((page) => setSharedInstances(page?.items || []));
    }
  }, [instance?.id]);

  const handleOnItemsChanged = (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const refreshData = useDebounce<string>(500, () => setLastFetchedTimestamp(Date.now()));

  const updateInput = (value: string): void => {
    setSearchInput(value);
    refreshData();
  };

  const getItems = (page: number, pageSize: number): Promise<PaginationResult<Document>> =>
    Api.getAllDocuments({
      schema_ids: schemaIds,
      page,
      page_size: pageSize,
      application_instance_id: selectedInstanceId,
      sort_by_title: sortBy === "name",
      sort: sortBy === "name" ? null : `$${sortBy}`,
      search: searchInput,
      order_asc: sortAscending,
      locale: selectedLanguage,
    }).fetchDirect({
      page: 0,
      page_size: 10,
      items: [],
      total_count: 0,
      count: 0,
    });

  return (
    <Container>
      <Box m={"16px 16px 0"} display="flex">
        <SearchField
          value={searchInput}
          fullWidth
          onChange={updateInput}
          placeholder={t("search.searchDocuments")}
          label={t("search.inputLabel")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
        />
        {instance && sharedInstances?.length ? (
          <Box flexBasis="30%">
            <KioSelect
              isHeaderVariant
              onChange={setSelectedInstanceId}
              options={[instance, ...sharedInstances].map((i) => ({
                label: resolveLocalizedString(i.owner_name) ?? i.name ?? "",
                value: i.id,
              }))}
              value={selectedInstanceId}
            />
          </Box>
        ) : null}
      </Box>
      {schemaIds && schemaIds.length ? (
        <DataList
          mapperFn={mapper}
          getItems={getItems}
          onItemClick={onSelect}
          selectedItems={selectedItems}
          handleOnItemsChanged={handleOnItemsChanged}
          externalDataChanged={externalDataChanged}
          initialSortOption={initialSortOption}
          resetPageDeps={resetPageDeps}
          onTagClicked={updateInput}
        />
      ) : (
        <Typography color={"error"} margin={"16px auto"}>
          {t("components.relationField.missing.uiSchema.schemaIds")}
        </Typography>
      )}
    </Container>
  );
};

export default DocumentListModalContent;
