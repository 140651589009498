import { Close as CloseIcon } from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton, styled, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Image from "@/components/Image";
import type Media from "@/declarations/models/Media";
import type { MediaSelectorFieldsetImageDimensions } from "@/framework/KioForm/fields/MediaSelectorField/MediaSelectorField";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";

export interface FullscreenPreviewProps {
  media: Media;
  setImageDimensions?: MediaSelectorFieldsetImageDimensions;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}
const StyledDialogContent = styled(DialogContent)`
  & img {
    padding: 0 8px 8px;
  }
`;

export const FullscreenPreview: FC<FullscreenPreviewProps> = ({ media, open, setOpen }) => {
  const { t } = useTranslation();

  return (
    <div>
      <FullScreenDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="preview"
      >
        <DialogTitle id="preview">
          <Typography component={"h1"}>{t("views.cms.preview.viewMode.preview")}</Typography>
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <StyledDialogContent>
          <Image src={media.src} alt={media.name} />
        </StyledDialogContent>
      </FullScreenDialog>
    </div>
  );
};
