import { styled } from "@mui/material";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type PaginationResult from "@/declarations/PaginationResult";
import Api from "@/services/Api";
import InstanceUserRole from "@/declarations/models/InstanceUserRole";

const VALID_ROUTES = ["instance"];

const UserListContainer = styled("div")`
  > div {
    height: fit-content;
  }
`;

export const InstanceUserList: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  const { pathname } = useLocation();
  const pathParams = pathname.split("/");
  const id = Number(pathParams.pop());
  const route = pathParams.pop() || "";

  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  if (!VALID_ROUTES.includes(route)) return null;

  const getUsers: DataListProps<InstanceUserRole>["getItems"] = (page: number, pageSize: number) => {
    const defaultValue: PaginationResult<InstanceUserRole> = {
      page,
      page_size: pageSize,
      count: 0,
      total_count: 0,
      items: [],
    };

    const fetchInstances = Api.getUsersByInstanceId({ application_instance_id: id, page, page_size: pageSize });
    return fetchInstances.fetchDirect(defaultValue);
  };

  const userMapper = (item: InstanceUserRole): DataListRow => ({
    key: `instance-${item.application_instance_id}-user-${item.user_id}`,
    title: `${item.name_of_user}`,
    subTitle: `${item.username}`,
    infoText: `Id: ${item.user_id}`,
    chipContent: t(`userRole.${item.user_role}`),
  });

  const onItemClick = (item: InstanceUserRole) => {
    history(`/admin/user/${item.user_id}`);
  };

  const deleteUserFromInstance = async (item: InstanceUserRole) => {
    const [_, error] = await Api.removeUserFromInstance(item.user_id, item.application_instance_id).fetch();
    if (error) {
      return Promise.reject("Unable to remove user from instance");
    }
    setLastFetchedTimestamp(Date.now());
  };

  return (
    <UserListContainer>
      <DataList
        listTitle={t("views.admin.instances.userList")}
        getItems={getUsers}
        mapperFn={userMapper}
        onItemClick={onItemClick}
        externalDataChanged={lastFetchedTimestamp}
        onDeleteItem={deleteUserFromInstance}
        disableSorting
      />
    </UserListContainer>
  );
};

export default InstanceUserList;
