import { type FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import { useModalManager } from "./store";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";
import { ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel/ModalWrapper";
import EditMediaView from "@/views/cms/EditorView/EditMediaView";

import DocumentListModalContent from "@/components/DocumentSelector/DocumentListModalContent";
import EditDocumentLoader from "@/views/cms/EditorView/EditDocumentLoader";

export const ModalManager: FC = () => {
  const mgr = useModalManager((s) => s);
  const { t } = useTranslation("common");
  const [documentLoaderIsDirty, setDocumentLoaderIsDirty] = useState(false); // It does not work to use setState from ArrayItems
  const [mediaEditorIsDirty, setMediaEditorIsDirty] = useState(false);

  useEffect(() => {
    setDocumentLoaderIsDirty(false);
  }, [mgr.editDocumentLoader?.props?.documentIdProp]);

  return (
    <>
      <FullScreenDialog maxWidth={"xs"} fullWidth open={mgr.testModal.isOpen} scroll={"paper"}>
        <ModalWrapper>{mgr.testModal.props.content}</ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={mgr.testModal.meta.onClose}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>

      <FullScreenDialog maxWidth={"xl"} fullWidth open={mgr.editMediaView.isOpen} scroll={"paper"}>
        <ModalWrapper>
          <EditMediaView {...mgr.editMediaView.props} isDirty={mediaEditorIsDirty} setIsDirty={setMediaEditorIsDirty} />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={mgr.editMediaView.meta.onClose}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>

      <FullScreenDialog
        maxWidth={"xl"}
        fullWidth
        open={mgr.documentListModalContent.isOpen}
        onClose={mgr.documentListModalContent.meta.onClose}
        scroll={"paper"}
      >
        <ModalWrapper>
          <DocumentListModalContent {...mgr.documentListModalContent.props} />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={mgr.documentListModalContent.meta.onClose}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>

      <FullScreenDialog
        maxWidth={"xl"}
        fullWidth
        open={mgr.editDocumentLoader.isOpen}
        onClose={mgr.editDocumentLoader.meta.onClose}
        scroll={"paper"}
      >
        <ModalWrapper>
          <EditDocumentLoader
            {...mgr.editDocumentLoader.props}
            isDirty={documentLoaderIsDirty}
            setIsDirty={setDocumentLoaderIsDirty}
          />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={mgr.editDocumentLoader.meta.onClose}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>
    </>
  );
};
