import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { ModalManagerState, ModalManagerStore } from "./types";

export const defaultState: ModalManagerState = {
  testModal: {
    key: 0,
    isOpen: false,
    meta: {
      onClose: () => void 0,
    },
    props: {
      content: "",
    },
  },
  editMediaView: {
    key: 0,
    isOpen: false,
    meta: {
      onClose: () => void 0,
    },
    props: {
      isDirty: false,
      setIsDirty: () => void 0,
    },
  },
  documentListModalContent: {
    key: 0,
    isOpen: false,
    meta: {
      onClose: () => void 0,
    },
    props: {
      // @ts-expect-error dummy mapper
      mapper: () => void 0,
    },
  },
  editDocumentLoader: {
    key: 0,
    isOpen: false,
    meta: {
      onClose: () => void 0,
    },
    props: {
      isDirty: false,
      setIsDirty: () => void 0,
    },
  },
};

export const useModalManager = create<ModalManagerStore>()(
  devtools(
    (set) => ({
      ...defaultState,

      open: (name, props, meta) =>
        set(
          (state) => ({
            ...state,
            [name]: {
              key: state[name].key + 1,
              isOpen: true,
              props: { ...props },
              meta: { ...meta },
            },
          }),
          false,
          "open"
        ),

      close: (name) =>
        set(
          (state) => ({
            ...state,
            [name]: {
              ...state[name],
              isOpen: false,
            },
            //return { ...state };
          }),
          false,
          "close"
        ),
    }),
    { name: "ModalManager" }
  )
);
