import type { FC } from "react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes as Switch, useLocation } from "react-router-dom";
import FinderView from "./FinderView/FinderView";
import MediaSelector from "@/components/MediaSelector/MediaSelector";
import { EditorStoreProvider } from "@/EditorContextProvider";
import MainLayout from "@/framework/MainLayout";
import DynamicMenu from "@/framework/Menu/DynamicMenu";
import { useStore } from "@/Store";
import ApplicationInstanceContextLoader from "@/views/cms/ApplicationInstanceContextLoader";
import EditDocumentLoader from "@/views/cms/EditorView/EditDocumentLoader";
import EditMediaView from "@/views/cms/EditorView/EditMediaView";
import EditOwnerView from "@/views/cms/EditorView/EditOwnerView";
import PageNotFoundView from "@/views/PageNotFoundView";

export const CMSRootView: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const { state } = useStore();
  const { pathname } = useLocation();
  const [mediaSelectorOpen, setMediaSelectorOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    if (isDirty) setIsDirty(false);
    // This prevents isDirty to be set when you open a new document when there are no changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Switch>
      <Route
        path="/:ownerSlug/:instanceSlug/media/:mediaId"
        element={
          <ApplicationInstanceContextLoader>
            <EditMediaView isDirty={isDirty} setIsDirty={setIsDirty} />
          </ApplicationInstanceContextLoader>
        }
      />

      <Route
        path="/owner/:ownerId"
        element={
          <MediaSelector open={mediaSelectorOpen} setOpen={setMediaSelectorOpen}>
            <MainLayout menuOpen={false} setMenuOpen={() => {}} hideBreadcrumbs>
              <EditOwnerView isDirty={isDirty} setIsDirty={setIsDirty} />
            </MainLayout>
          </MediaSelector>
        }
      />

      <Route
        path="/:ownerSlug/:instanceSlug/create"
        element={
          <ApplicationInstanceContextLoader>
            <EditorStoreProvider>
              <EditDocumentLoader isDirty={isDirty} setIsDirty={setIsDirty} />
            </EditorStoreProvider>
          </ApplicationInstanceContextLoader>
        }
      />

      <Route
        path="/:ownerSlug/:instanceSlug/:documentId"
        element={
          <ApplicationInstanceContextLoader>
            <EditorStoreProvider>
              <EditDocumentLoader isDirty={isDirty} setIsDirty={setIsDirty} />
            </EditorStoreProvider>
          </ApplicationInstanceContextLoader>
        }
      />

      <Route
        path="/:ownerSlug/:instanceSlug"
        element={
          <MainLayout
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            menuContent={<DynamicMenu applicationId={state.cmsContextInstance?.application_id} showDocumentSearch />}
            hideBreadcrumbs
          >
            <ApplicationInstanceContextLoader>
              <FinderView />
            </ApplicationInstanceContextLoader>
          </MainLayout>
        }
      />

      <Route path="/" element={<Navigate to="/dashboard" />} />

      <Route path="*" element={<PageNotFoundView />} />
    </Switch>
  );
};

export default CMSRootView;
